.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  height:100px;
}

.Brand {
  display: flex;
  flex-direction: row;
  text-align: center;;
  justify-content: center;
  width: 200px;
  height: 50px;
  margin: auto;
  padding-top: 25px;
  font-size: 20px;
  font-weight: bold;
  top:50%;
}

.Button {
  width: 150px;
  height: 50px;
  margin: 20px;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  padding:10px;
  text-align: center;
}

.SubMenu {
  display: flex;
  width: 100%;
  height: 50px;
  margin: 20px;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  padding:20px;
  text-align: center;
}

.SubButton {
  width: 200px;
  height: 50px;
  margin: 20px;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  padding:10px;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Footer {
  background-color: #282c34;
  position:absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height:100px;
  width: 100%;
  bottom:20px;
  text-align: center;
}